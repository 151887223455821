import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const Gilleleje = ({ svgRef, routeRef, secondaryRouteRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 768">
			<g id="gilleleje-hilleroed" fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path ref={routeRef} className="route" fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M358.07 361.93H1103.65"/>
				<path ref={secondaryRouteRef} className="secondary-route" fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M145.04 361.93H358.06"/>
				<g className="stations">
					<g className="end-stop">
						<circle className="dot" cx="145.04" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text className="caption" transform="rotate(-45 490.348 -12.729)" fontSize="14" fill="#00275e" fontWeight="700">Hillerød</text>
					</g>
					<g className="stop">
						<circle id="dot-2" className="dot" cx="251.55" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text id="caption-2" className="caption" transform="rotate(-45 543.338 -140.658)" fontSize="14" fill="#00275e" fontWeight="700">Slotspavillonen</text>
					</g>
					<g id="stop-2" className="stop">
						<text id="caption-3" className="caption" transform="rotate(-45 596.598 -269.239)" fontSize="14" fill="#00275e" fontWeight="700">Kagerup</text>
						<g id="dot-3" className="dot">
							<circle cx="358.06" cy="361.93" r="7" fill="#fff"/>
							<circle cx="358.06" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="358.06" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-3" className="stop">
						<text id="caption-4" className="caption" transform="rotate(-45 649.853 -397.808)" fontSize="14" fill="#00275e" fontWeight="700">Mårum</text>
						<g id="dot-4" className="dot">
							<circle cx="464.58" cy="361.93" r="7" fill="#fff"/>
							<circle cx="464.58" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="464.58" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-4" className="stop">
						<text id="caption-5" className="caption" transform="rotate(-45 703.108 -526.377)" fontSize="14" fill="#00275e" fontWeight="700">Saltrup</text>
						<g id="dot-5" className="dot">
							<circle cx="571.09" cy="361.93" r="7" fill="#fff"/>
							<circle cx="571.09" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="571.09" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-5" className="stop">
						<circle id="dot-6" className="dot" cx="677.6" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text id="caption-6" className="caption" transform="rotate(-45 755.563 -653.014)" fontSize="14" fill="#00275e" fontWeight="700">Græsted Syd</text>
					</g>
					<g id="stop-6" className="stop">
						<circle id="dot-7" className="dot" cx="784.11" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text id="caption-7" className="caption" transform="rotate(-45 809.623 -783.527)" fontSize="14" fill="#00275e" fontWeight="700">Græsted</text>
					</g>
					<g id="stop-7" className="stop">
						<text id="caption-8" className="caption" transform="rotate(-45 862.878 -912.096)" fontSize="14" fill="#00275e" fontWeight="700">Pårup</text>
						<g id="dot-8" className="dot">
							<circle cx="890.63" cy="361.93" r="7" fill="#fff"/>
							<circle cx="890.63" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="890.63" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-8" className="stop">
						<text id="caption-9" className="caption" transform="rotate(-45 916.133 -1040.665)" fontSize="14" fill="#00275e" fontWeight="700">Fjellenstrup</text>
						<g id="dot-9" className="dot">
							<circle cx="997.14" cy="361.93" r="7" fill="#fff"/>
							<circle cx="997.14" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="997.14" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g className="start-stop">
						<circle id="dot-10" className="dot" cx="1103.65" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text id="caption-10" className="caption" transform="rotate(-45 969.393 -1169.246)" fontSize="14" fill="#00275e" fontWeight="700">Gilleleje</text>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Gilleleje;

import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const Frederiksvaerk = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 768">
			<g id="frederiksvaerk" fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path ref={routeRef} className="route" fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M145.04 361.93h958.61"/>
				<g className="stations">
					<g className="start-stop">
						<circle className="dot" cx="145.04" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text className="caption" transform="rotate(-45 490.348 -12.729)" fontSize="14" fill="#00275e" fontWeight="700">Hundested Havn</text>
					</g>
					<g className="stop">
						<text id="caption-2" className="caption" transform="rotate(-45 522.038 -89.235)" fontSize="14" fill="#00275e" fontWeight="700">Hundested</text>
						<circle id="dot-2" className="dot" cx="204.94" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-2" className="stop">
						<text id="caption-3" className="caption" transform="rotate(-45 553.993 -166.381)" fontSize="14" fill="#00275e" fontWeight="700" letterSpacing=".01em">Vibehus</text>
						<circle id="dot-3" className="dot" cx="264.85" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-3" className="stop">
						<text id="caption-4" className="caption" transform="rotate(-45 579.668 -226.295)" fontSize="14" fill="#00275e" fontWeight="700">Østerbjerg</text>
						<g id="dot-4" className="dot">
							<circle cx="322.76" cy="361.93" r="7" fill="#fff"/>
							<circle cx="322.76" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="322.76" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-4" className="stop">
						<text id="caption-5" className="caption" transform="rotate(-45 609.863 -294.364)" fontSize="14" fill="#00275e" fontWeight="700">Dyssekilde</text>
						<g id="dot-5" className="dot">
							<circle cx="380.67" cy="361.93" r="7" fill="#fff"/>
							<circle cx="380.67" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="380.67" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-5" className="stop">
						<text id="caption-6" className="caption" transform="rotate(-45 641.058 -362.433)" fontSize="14" fill="#00275e" fontWeight="700">Melby</text>
						<circle id="dot-6" className="dot" cx="440.58" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-6" className="stop">
						<text id="caption-7" className="caption" transform="rotate(-45 669.253 -440.501)" fontSize="14" fill="#00275e" fontWeight="700">Hanehoved</text>
						<g id="dot-7" className="dot">
							<circle cx="504.48" cy="361.93" r="7" fill="#fff"/>
							<circle cx="504.48" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="504.48" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-7" className="stop">
						<text id="caption-8" className="caption" transform="rotate(-45 697.448 -510.57)" fontSize="14" fill="#00275e" fontWeight="700">Fredriksværk</text>
						<circle id="dot-8" className="dot" cx="564.39" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-8" className="stop">
						<text id="caption-9" className="caption" transform="rotate(-45 725.638 -586.627)" fontSize="14" fill="#00275e" fontWeight="700">Lille Kregme</text>
						<g id="dot-9" className="dot">
							<circle cx="622.3" cy="361.93" r="7" fill="#fff"/>
							<circle cx="622.3" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="622.3" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-9" className="stop">
						<text id="caption-10" className="caption" transform="rotate(-45 753.833 -664.696)" fontSize="14" fill="#00275e" fontWeight="700">Kregme</text>
						<g id="dot-10" className="dot">
							<circle cx="686.21" cy="361.93" r="7" fill="#fff"/>
							<circle cx="686.21" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="686.21" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-10" className="stop">
						<text id="caption-11" className="caption" transform="rotate(-45 785.028 -742.764)" fontSize="14" fill="#00275e" fontWeight="700">Ølsted</text>
						<circle id="dot-11" className="dot" cx="750.11" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-11" className="stop">
						<text id="caption-12" className="caption" transform="rotate(-45 820.223 -820.833)" fontSize="14" fill="#00275e" fontWeight="700">Grimstrup</text>
						<g id="dot-12" className="dot">
							<circle cx="814.02" cy="361.93" r="7" fill="#fff"/>
							<circle cx="814.02" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="814.02" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-12" className="stop">
						<text id="caption-13" className="caption" transform="rotate(-45 848.418 -888.902)" fontSize="14" fill="#00275e" fontWeight="700">Skævinge</text>
						<circle id="dot-13" className="dot" cx="870.93" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-13" className="stop">
						<text id="caption-14" className="caption" transform="rotate(-45 873.528 -957.807)" fontSize="14" fill="#00275e" fontWeight="700">Gørløse</text>
						<g id="dot-14" className="dot">
							<circle cx="931.84" cy="361.93" r="7" fill="#fff"/>
							<circle cx="931.84" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="931.84" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-14" className="stop">
						<text id="caption-15" className="caption" transform="rotate(-45 909.861 -1030.453)" fontSize="14" fill="#00275e" fontWeight="700">Brødeskov</text>
						<g id="dot-15" className="dot">
							<circle cx="992.75" cy="361.93" r="7" fill="#fff"/>
							<circle cx="992.75" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="992.75" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-15" className="stop">
						<text id="caption-16" className="caption" transform="rotate(-45 937.438 -1092.1)" fontSize="14" fill="#00275e" fontWeight="700">Favrholm</text>
						<circle id="dot-16" className="dot" cx="1050.65" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="end-stop">
						<text id="caption-17" className="caption" transform="rotate(-45 969.393 -1169.246)" fontSize="14" fill="#00275e" fontWeight="700">Hillerød</text>
						<circle id="dot-17" className="dot" cx="1103.65" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Frederiksvaerk;

import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const OestbanenMobile = ({ svgRef, routeRef, secondaryRouteRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 414 768">
			<g className="oestbanen-mobile" fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path className="secondary-route" ref={secondaryRouteRef} fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M377.8 653.04V416.2H223.86L207 386.69"/>
				<path className="route" ref={routeRef} fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M207 37.24v349.45l-16.86 29.51H36.2v301.91"/>
				<g className="stations">
					<g className="start-stop">
						<text className="caption" transform="translate(224.09 42.11)" fontSize="14" fill="#00275e" fontWeight="700">Roskilde</text>
						<circle className="dot" cx="207" cy="37.24" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop">
						<text className="caption" transform="translate(224.09 77.14)" fontSize="14" fill="#00275e" fontWeight="700">Gadstup</text>
						<circle id="dot-2" className="dot" cx="207" cy="72.18" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-2" className="stop">
						<text className="caption" transform="translate(224.09 112.08)" fontSize="14" fill="#00275e" fontWeight="700">Havdrup</text>
						<circle id="dot-3" className="dot" cx="207" cy="107.13" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-3" className="stop">
						<text className="caption" transform="translate(224.09 147.03)" fontSize="14" fill="#00275e" fontWeight="700">Lille Skensved</text>
						<circle id="dot-4" className="dot" cx="207" cy="142.07" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-4" className="stop">
						<text className="caption" transform="translate(224.09 181.97)" fontSize="14" fill="#00275e" fontWeight="700">Ølby</text>
						<circle id="dot-5" className="dot" cx="207" cy="177.02" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="intermediate-stop">
						<text className="caption" transform="translate(224.09 216.92)" fontSize="14" fill="#00275e" fontWeight="700">Køge</text>
						<circle id="dot-6" className="dot" cx="207" cy="211.96" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-5" className="stop">
						<text className="caption" transform="translate(224.09 251.86)" fontSize="14" fill="#00275e" fontWeight="700">Egøje</text>
						<g id="dot-7" className="dot">
							<circle cx="207" cy="246.91" r="7" fill="#fff"/>
							<circle cx="207" cy="246.91" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="246.91" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-6" className="stop">
						<text className="caption" transform="translate(224.09 286.81)" fontSize="14" fill="#00275e" fontWeight="700">Vallø</text>
						<g id="dot-8" className="dot">
							<circle cx="207" cy="281.85" r="7" fill="#fff"/>
							<circle cx="207" cy="281.85" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="281.85" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-7" className="stop">
						<text className="caption" transform="translate(224.09 321.75)" fontSize="14" fill="#00275e" fontWeight="700">Grubberholm</text>
						<g id="dot-9" className="dot">
							<circle cx="207" cy="316.8" r="7" fill="#fff"/>
							<circle cx="207" cy="316.8" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="316.8" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-8" className="stop">
						<text className="caption" transform="translate(224.09 356.7)" fontSize="14" fill="#00275e" fontWeight="700">Himlingøje</text>
						<g id="dot-10" className="dot">
							<circle cx="207" cy="351.74" r="7" fill="#fff"/>
							<circle cx="207" cy="351.74" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="351.74" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="intermediate-stop-2" className="intermediate-stop">
						<text className="caption" transform="translate(224.09 391.64)" fontSize="14" fill="#00275e" fontWeight="700">Hårlev</text>
						<circle id="dot-11" className="dot" cx="207" cy="386.69" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-9" className="stop">
						<g id="dot-12" className="dot">
							<circle cx="36.2" cy="464.69" r="7" fill="#fff"/>
							<circle cx="36.2" cy="464.69" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="36.2" cy="464.69" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text className="caption" transform="translate(52.22 469.64)" fontSize="14" fill="#00275e" fontWeight="700">Lille Linde</text>
					</g>
					<g id="stop-10" className="stop">
						<g id="dot-13" className="dot">
							<circle cx="36.2" cy="527.42" r="7" fill="#fff"/>
							<circle cx="36.2" cy="527.42" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="36.2" cy="527.42" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text className="caption" transform="translate(52.22 532.37)" fontSize="14" fill="#00275e" fontWeight="700">Karise</text>
					</g>
					<g id="stop-11" className="stop">
						<g id="dot-14" className="dot">
							<circle cx="36.2" cy="590.15" r="7" fill="#fff"/>
							<circle cx="36.2" cy="590.15" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="36.2" cy="590.15" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text className="caption" transform="translate(52.22 595.1)" fontSize="14" fill="#00275e" fontWeight="700">Tokkerup</text>
					</g>
					<g id="stop-12" className="stop">
						<g id="dot-15" className="dot">
							<circle cx="36.2" cy="652.88" r="7" fill="#fff"/>
							<circle cx="36.2" cy="652.88" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="36.2" cy="652.88" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text className="caption" transform="translate(52.22 657.83)" fontSize="14" fill="#00275e" fontWeight="700">Faxe Syd</text>
					</g>
					<g className="end-stop">
						<circle id="dot-16" className="dot" cx="36.2" cy="715.61" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text className="caption" transform="translate(52.22 720.56)" fontSize="14" fill="#00275e" fontWeight="700">Faxe Ladeplads</text>
					</g>
					<g id="stop-13" className="stop">
						<text className="caption" transform="translate(304.45 469.64)" fontSize="14" fill="#00275e" fontWeight="700">Varpelev</text>
						<g id="dot-17" className="dot">
							<circle cx="377.8" cy="464.69" r="7" fill="#fff"/>
							<circle cx="377.8" cy="464.69" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="377.8" cy="464.69" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-14" className="stop">
						<text className="caption" transform="translate(298.73 532.37)" fontSize="14" fill="#00275e" fontWeight="700">Klippinge</text>
						<g id="dot-18" className="dot">
							<circle cx="377.8" cy="527.42" r="7" fill="#fff"/>
							<circle cx="377.8" cy="527.42" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="377.8" cy="527.42" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-15" className="stop">
						<text className="caption" transform="translate(258 595.1)" fontSize="14" fill="#00275e" fontWeight="700">Store Heddinge</text>
						<circle className="dot" cx="377.8" cy="590.15" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="secondary-end-stop">
						<text className="caption" transform="translate(315.88 657.83)" fontSize="14" fill="#00275e" fontWeight="700">Rødvig</text>
						<circle id="dot-20" className="dot" cx="377.8" cy="652.88" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default OestbanenMobile;

import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const Naerum = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 768">
			<g id="naerum" fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path ref={routeRef} className="route" fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M145.04 361.93h958.61"/>
				<g className="stations">
					<g className="start-stop">
						<circle className="dot" cx="145.04" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text className="caption" transform="rotate(-45 490.348 -12.729)" fontSize="14" fill="#00275e" fontWeight="700">Jægersborg</text>
					</g>
					<g className="stop">
						<text className="caption" transform="rotate(-45 558.558 -177.402)" fontSize="14" fill="#00275e" fontWeight="700">Nørgaardsvej</text>
						<g className="dot">
							<circle cx="281.98" cy="361.93" r="7" fill="#fff"/>
							<circle cx="281.98" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="281.98" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g className="stop">
						<text className="caption" transform="rotate(-45 627.028 -342.703)" fontSize="14" fill="#00275e" fontWeight="700">Lyngby Lokal</text>
						<circle className="dot" cx="418.93" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="stop">
						<text className="caption" transform="rotate(-45 695.503 -508.017)" fontSize="14" fill="#00275e" fontWeight="700">Fuglevad</text>
						<circle className="dot" cx="555.87" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="stop">
						<text className="caption" transform="rotate(-45 762.766 -673.818)" fontSize="14" fill="#00275e" fontWeight="700">Brede</text>
						<circle className="dot" cx="692.82" cy="360.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="stop">
						<text className="caption" transform="rotate(-45 832.448 -838.631)" fontSize="14" fill="#00275e" fontWeight="700">Ørholm</text>
						<circle className="dot" cx="829.76" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="stop">
						<text className="caption" transform="rotate(-45 900.918 -1003.932)" fontSize="14" fill="#00275e" fontWeight="700">Ravnholm</text>
						<circle className="dot" cx="966.71" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="end-stop">
						<text className="caption" transform="rotate(-45 969.393 -1169.246)" fontSize="14" fill="#00275e" fontWeight="700">Nærum</text>
						<circle className="dot" cx="1103.65" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Naerum;

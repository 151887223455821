import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const Oestbanen = ({ svgRef, routeRef, secondaryRouteRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 768">
			<g id="oestbanen" fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path className="route" ref={routeRef} fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M1103.65 558.94V378.93l-69.07-17H145.04"/>
				<path className="secondary-route" ref={secondaryRouteRef} fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M1103.65 207.65v141.28l-69.07 13"/>
				<g className='stations'>
					<g className="secondary-end-stop">
						<circle className="dot" cx="1103.65" cy="207.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text className="caption" transform="translate(1118.02 212.88)" fontSize="14" fill="#00275e" fontWeight="700">Rødvig</text>
					</g>
					<g className="stop">
						<circle className="dot" cx="1103.65" cy="246.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text id="caption-2" className="caption" transform="translate(1118.02 251.88)" fontSize="14" fill="#00275e" fontWeight="700">Store Heddinge</text>
					</g>
					<g id="stop-2" className="stop">
						<g id="dot-3" className="dot">
							<circle cx="1103.65" cy="285.93" r="7" fill="#fff"/>
							<circle cx="1103.65" cy="285.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="1103.65" cy="285.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text id="caption-3" className="caption" transform="translate(1118.02 290.89)" fontSize="14" fill="#00275e" fontWeight="700">Klippinge</text>
					</g>
					<g id="stop-3" className="stop">
						<g id="dot-4" className="dot">
							<circle cx="1103.65" cy="324.93" r="7" fill="#fff"/>
							<circle cx="1103.65" cy="324.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="1103.65" cy="324.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text id="caption-4" className="caption" transform="translate(1118.02 329.89)" fontSize="14" fill="#00275e" fontWeight="700">Varpelev</text>
					</g>
					<g className="end-stop">
						<circle id="dot-5" className="dot" cx="1103.65" cy="558.94" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text id="caption-5" className="caption" transform="translate(1118.02 563.9)" fontSize="14" fill="#00275e" fontWeight="700">Faxe Ladeplads</text>
					</g>
					<g id="stop-4" className="stop">
						<text id="caption-6" className="caption" transform="translate(1118.02 524.89)" fontSize="14" fill="#00275e" fontWeight="700">Faxe Syd</text>
						<g id="dot-6" className="dot">
							<circle cx="1103.65" cy="519.94" r="7" fill="#fff"/>
							<circle cx="1103.65" cy="519.94" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="1103.65" cy="519.94" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-5" className="stop">
						<g id="dot-7" className="dot">
							<circle cx="1103.65" cy="480.94" r="7" fill="#fff"/>
							<circle cx="1103.65" cy="480.94" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="1103.65" cy="480.94" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text id="caption-7" className="caption" transform="translate(1118.02 485.89)" fontSize="14" fill="#00275e" fontWeight="700">Tokkerup</text>
					</g>
					<g id="stop-6" className="stop">
						<g id="dot-8" className="dot">
							<circle cx="1103.65" cy="441.94" r="7" fill="#fff"/>
							<circle cx="1103.65" cy="441.94" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="1103.65" cy="441.94" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text id="caption-8" className="caption" transform="translate(1118.02 446.89)" fontSize="14" fill="#00275e" fontWeight="700">Karise</text>
					</g>
					<g id="stop-7" className="stop">
						<g id="dot-9" className="dot">
							<circle cx="1103.65" cy="402.93" r="7" fill="#fff"/>
							<circle cx="1103.65" cy="402.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="1103.65" cy="402.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text id="caption-9" className="caption" transform="translate(1118.02 407.89)" fontSize="14" fill="#00275e" fontWeight="700">Lille Linde</text>
					</g>
					<g id="stop-8" className="intermediate-stop">
						<text id="caption-10" className="caption" transform="rotate(-45 934.828 -1085.798)" fontSize="14" fill="#00275e" fontWeight="700">Hårlev</text>
						<circle id="dot-10" className="dot" cx="1034.58" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-9" className="stop">
						<g id="dot-11" className="dot">
							<circle cx="945.63" cy="361.93" r="7" fill="#fff"/>
							<circle cx="945.63" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="945.63" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text id="caption-11" className="caption" transform="rotate(-45 890.353 -978.426)" fontSize="14" fill="#00275e" fontWeight="700">Himlingøje</text>
					</g>
					<g id="stop-10" className="stop">
						<g id="dot-12" className="dot">
							<circle cx="856.67" cy="361.93" r="7" fill="#fff"/>
							<circle cx="856.67" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="856.67" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text id="caption-12" className="caption" transform="rotate(-45 845.873 -871.042)" fontSize="14" fill="#00275e" fontWeight="700">Grubberholm</text>
					</g>
					<g id="stop-11" className="stop">
						<g id="dot-13" className="dot">
							<circle cx="767.72" cy="361.93" r="7" fill="#fff"/>
							<circle cx="767.72" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="767.72" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text id="caption-13" className="caption" transform="rotate(-45 801.398 -763.67)" fontSize="14" fill="#00275e" fontWeight="700">Vallø</text>
					</g>
					<g id="stop-12" className="stop">
						<g id="dot-14" className="dot">
							<circle cx="678.77" cy="361.93" r="7" fill="#fff"/>
							<circle cx="678.77" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="678.77" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text id="caption-14" className="caption" transform="rotate(-45 756.923 -656.298)" fontSize="14" fill="#00275e" fontWeight="700">Egøje</text>
					</g>
					<g className="intermediate-stop">
						<circle id="dot-15" className="dot" cx="589.81" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text id="caption-15" className="caption" transform="rotate(-45 712.443 -548.913)" fontSize="14" fill="#00275e" fontWeight="700">Køge</text>
					</g>
					<g id="stop-13" className="stop">
						<circle id="dot-16" className="dot" cx="500.86" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text id="caption-16" className="caption" transform="rotate(-45 667.968 -441.541)" fontSize="14" fill="#00275e" fontWeight="700">Ølby</text>
					</g>
					<g id="stop-14" className="stop">
						<circle id="dot-17" className="dot" cx="411.9" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text id="caption-17" className="caption" transform="rotate(-45 623.488 -334.157)" fontSize="14" fill="#00275e" fontWeight="700">Lille Skensved</text>
					</g>
					<g id="stop-15" className="stop">
						<circle id="dot-18" className="dot" cx="322.95" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text id="caption-18" className="caption" transform="rotate(-45 579.013 -226.785)" fontSize="14" fill="#00275e" fontWeight="700">Havdrup</text>
					</g>
					<g id="stop-16" className="stop">
						<circle id="dot-19" className="dot" cx="233.99" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text id="caption-19" className="caption" transform="rotate(-45 534.443 -119.183)" fontSize="14" fill="#00275e" fontWeight="700">Gadstrup</text>
					</g>
					<g className="start-stop">
						<circle id="dot-20" className="dot" cx="145.04" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text id="caption-20" className="caption" transform="rotate(-45 490.348 -12.729)" fontSize="14" fill="#00275e" fontWeight="700">Roskilde</text>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Oestbanen;

import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const NaerumMobile = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 414 768">
			<g id="naerum-mobile" fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path ref={routeRef} className="route" fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M207 37.24v678.37"/>
				<g className="stations">
					<g className="start-stop">
						<text className="caption" transform="translate(224.09 42.19)" fontSize="14" fill="#00275e" fontWeight="700">Jægersborg</text>
						<circle className="dot" cx="207" cy="37.24" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop">
						<text className="caption" transform="translate(224.09 139.11)" fontSize="14" fill="#00275e" fontWeight="700">Nørgaardsvej</text>
						<g id="dot-2" className="dot">
							<circle cx="207" cy="134.15" r="7" fill="#fff"/>
							<circle cx="207" cy="134.15" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="134.15" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-2" className="stop">
						<text className="caption" transform="translate(224.09 236.02)" fontSize="14" fill="#00275e" fontWeight="700">Lyngby Lokal</text>
						<circle id="dot-3" className="dot" cx="207" cy="231.06" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-3" className="stop">
						<text className="caption" transform="translate(224.09 332.92)" fontSize="14" fill="#00275e" fontWeight="700">Fuglevad</text>
						<circle id="dot-4" className="dot" cx="207" cy="327.97" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-4" className="stop">
						<text className="caption" transform="translate(224.09 429.83)" fontSize="14" fill="#00275e" fontWeight="700">Brede</text>
						<circle id="dot-5" className="dot" cx="207" cy="424.88" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-5" className="stop">
						<text className="caption" transform="translate(224.09 526.74)" fontSize="14" fill="#00275e" fontWeight="700">Ørholm</text>
						<circle id="dot-6" className="dot" cx="207" cy="521.79" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-6" className="stop">
						<text className="caption" transform="translate(224.09 623.65)" fontSize="14" fill="#00275e" fontWeight="700">Ravnholm</text>
						<circle id="dot-7" className="dot" cx="207" cy="618.7" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="end-stop">
						<text className="caption" transform="translate(224.09 720.56)" fontSize="14" fill="#00275e" fontWeight="700">Nærum</text>
						<circle id="dot-8" className="dot" cx="207" cy="715.61" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default NaerumMobile;

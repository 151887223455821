import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const GillelejeMobile = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 414 768">
			<g id="gilleleje-hilleroed-mobile" fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path ref={routeRef} className="route" fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M207 37.24v678.37"/>
				<g className="stations">
					<g className="start-stop">
						<text className="caption" transform="translate(224.09 720.56)" fontSize="14" fill="#00275e" fontWeight="700">Gilleleje</text>
						<circle className="dot" cx="207" cy="715.61" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="stop">
						<text id="caption-2" className="caption" transform="translate(224.09 645.19)" fontSize="14" fill="#00275e" fontWeight="700">Fjellenstrup</text>
						<g id="dot-2" className="dot">
							<circle cx="207" cy="640.23" r="7" fill="#fff"/>
							<circle cx="207" cy="640.23" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="640.23" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-2" className="stop">
						<text id="caption-3" className="caption" transform="translate(224.09 569.81)" fontSize="14" fill="#00275e" fontWeight="700">Pårup</text>
						<g id="dot-3" className="dot">
							<circle cx="207" cy="564.86" r="7" fill="#fff"/>
							<circle cx="207" cy="564.86" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="564.86" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-3" className="stop">
						<text id="caption-4" className="caption" transform="translate(224.09 494.44)" fontSize="14" fill="#00275e" fontWeight="700">Græsted</text>
						<circle id="dot-4" className="dot" cx="207" cy="489.49" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-4" className="stop">
						<text id="caption-5" className="caption" transform="translate(224.09 419.07)" fontSize="14" fill="#00275e" fontWeight="700">Græsted Syd</text>
						<circle id="dot-5" className="dot" cx="207" cy="414.11" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-5" className="stop">
						<text id="caption-6" className="caption" transform="translate(224.09 343.69)" fontSize="14" fill="#00275e" fontWeight="700">Saltrup</text>
						<g id="dot-6" className="dot">
							<circle cx="207" cy="338.74" r="7" fill="#fff"/>
							<circle cx="207" cy="338.74" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="338.74" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-6" className="stop">
						<text id="caption-7" className="caption" transform="translate(224.09 268.32)" fontSize="14" fill="#00275e" fontWeight="700">Mårum</text>
						<g id="dot-7" className="dot">
							<circle cx="207" cy="263.36" r="7" fill="#fff"/>
							<circle cx="207" cy="263.36" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="263.36" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-7" className="stop">
						<text id="caption-8" className="caption" transform="translate(224.09 192.94)" fontSize="14" fill="#00275e" fontWeight="700">Kagerup</text>
						<g id="dot-8" className="dot">
							<circle cx="207" cy="187.99" r="7" fill="#fff"/>
							<circle cx="207" cy="187.99" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="187.99" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-8" className="stop">
						<text id="caption-9" className="caption" transform="translate(224.09 117.57)" fontSize="14" fill="#00275e" fontWeight="700">Slotspavillonen</text>
						<circle id="dot-9" className="dot" cx="207" cy="112.61" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="end-stop">
						<text id="caption-10" className="caption" transform="translate(224.09 44.19)" fontSize="14" fill="#00275e" fontWeight="700">Hillerød</text>
						<circle id="dot-10" className="dot" cx="207" cy="39.24" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default GillelejeMobile;

import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const LillenordMobile = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 414 768">
			<g id="lillenord-mobile" fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path ref={routeRef} className="route" fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M207 37.24v678.37"/>
				<g className="stations">
					<g className="start-stop">
						<text className="caption" transform="translate(224.09 42.19)" fontSize="14" fill="#00275e" fontWeight="700">Hillerød</text>
						<circle className="dot" cx="207" cy="37.24" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="stop">
						<text id="caption-2" className="caption" transform="translate(224.09 126.99)" fontSize="14" fill="#00275e" fontWeight="700">Grønholt</text>
						<g id="dot-2" className="dot">
							<circle cx="207" cy="122.04" r="7" fill="#fff"/>
							<circle cx="207" cy="122.04" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="122.04" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-2" className="stop">
						<text id="caption-3" className="caption" transform="translate(224.09 211.79)" fontSize="14" fill="#00275e" fontWeight="700">Kratbjerg</text>
						<g id="dot-3" className="dot">
							<circle cx="207" cy="206.83" r="7" fill="#fff"/>
							<circle cx="207" cy="206.83" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="206.83" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-3" className="stop">
						<text id="caption-4" className="caption" transform="translate(224.09 296.58)" fontSize="14" fill="#00275e" fontWeight="700">Fredensborg</text>
						<circle id="dot-4" className="dot" cx="207" cy="291.63" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-4" className="stop">
						<text id="caption-5" className="caption" transform="translate(224.09 381.38)" fontSize="14" fill="#00275e" fontWeight="700">Langerød</text>
						<g id="dot-5" className="dot">
							<circle cx="207" cy="376.42" r="7" fill="#fff"/>
							<circle cx="207" cy="376.42" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="376.42" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-5" className="stop">
						<text id="caption-6" className="caption" transform="translate(224.09 466.18)" fontSize="14" fill="#00275e" fontWeight="700">Kvistgård</text>
						<circle id="dot-6" className="dot" cx="207" cy="461.22" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-6" className="stop">
						<text id="caption-7" className="caption" transform="translate(224.09 550.97)" fontSize="14" fill="#00275e" fontWeight="700">Mørdrup</text>
						<g id="dot-7" className="dot">
							<circle cx="207" cy="546.02" r="7" fill="#fff"/>
							<circle cx="207" cy="546.02" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="546.02" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-7" className="stop">
						<text id="caption-8" className="caption" transform="translate(224.09 635.77)" fontSize="14" fill="#00275e" fontWeight="700">Snekkersten</text>
						<circle id="dot-8" className="dot" cx="207" cy="630.81" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="end-stop">
						<text id="caption-9" className="caption" transform="translate(224.09 720.56)" fontSize="14" fill="#00275e" fontWeight="700">Helsingør</text>
						<circle id="dot-9" className="dot" cx="207" cy="715.61" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default LillenordMobile;

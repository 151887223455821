import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const FrederiksvaerkMobile = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg'  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 414 768">
			<g id="frederiksvaerk-mobile" fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path ref={routeRef} className="route" fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M207 37.24v678.37"/>
				<g className="stations">
					<g className="start-stop">
						<text className="caption" transform="translate(224.09 42.19)" fontSize="14" fill="#00275e" fontWeight="700">Hundested Havn</text>
						<circle className="dot" cx="207" cy="37.24" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="stop">
						<text id="caption-2" className="caption" transform="translate(224.09 80.42)" fontSize="14" fill="#00275e" fontWeight="700">Hundested</text>
						<circle id="dot-2" className="dot" cx="207" cy="75.46" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-2" className="stop">
						<text id="caption-3" className="caption" transform="translate(224.09 116.65)" fontSize="14" fill="#00275e" fontWeight="700" letterSpacing=".01em">Vibehus</text>
						<circle id="dot-3" className="dot" cx="207" cy="112.69" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-3" className="stop">
						<text id="caption-4" className="caption" transform="translate(224.09 160.87)" fontSize="14" fill="#00275e" fontWeight="700">Østerbjerg</text>
						<g id="dot-4" className="dot">
							<circle cx="207" cy="155.91" r="7" fill="#fff"/>
							<circle cx="207" cy="155.91" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="155.91" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-4" className="stop">
						<text id="caption-5" className="caption" transform="translate(224.09 203.09)" fontSize="14" fill="#00275e" fontWeight="700">Dyssekilde</text>
						<g id="dot-5" className="dot">
							<circle cx="207" cy="198.14" r="7" fill="#fff"/>
							<circle cx="207" cy="198.14" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="198.14" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-5" className="stop">
						<text id="caption-6" className="caption" transform="translate(224.09 248.32)" fontSize="14" fill="#00275e" fontWeight="700">Melby</text>
						<circle id="dot-6" className="dot" cx="207" cy="243.36" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-6" className="stop">
						<text id="caption-7" className="caption" transform="translate(224.09 300.54)" fontSize="14" fill="#00275e" fontWeight="700">Hanehoved</text>
						<g id="dot-7" className="dot">
							<circle cx="207" cy="295.59" r="7" fill="#fff"/>
							<circle cx="207" cy="295.59" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="295.59" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-7" className="stop">
						<text id="caption-8" className="caption" transform="translate(224.09 345.77)" fontSize="14" fill="#00275e" fontWeight="700">Fredriksværk</text>
						<circle id="dot-8" className="dot" cx="207" cy="340.81" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-8" className="stop">
						<text id="caption-9" className="caption" transform="translate(224.09 394.99)" fontSize="14" fill="#00275e" fontWeight="700">Lille Kregme</text>
						<g id="dot-9" className="dot">
							<circle cx="207" cy="390.04" r="7" fill="#fff"/>
							<circle cx="207" cy="390.04" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="390.04" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-9" className="stop">
						<text id="caption-10" className="caption" transform="translate(224.09 435.22)" fontSize="14" fill="#00275e" fontWeight="700">Kregme</text>
						<g id="dot-10" className="dot">
							<circle cx="207" cy="430.26" r="7" fill="#fff"/>
							<circle cx="207" cy="430.26" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="430.26" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-10" className="stop">
						<text id="caption-11" className="caption" transform="translate(224.09 475.44)" fontSize="14" fill="#00275e" fontWeight="700">Ølsted</text>
						<circle id="dot-11" className="dot" cx="207" cy="470.49" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-11" className="stop">
						<text id="caption-12" className="caption" transform="translate(224.09 515.67)" fontSize="14" fill="#00275e" fontWeight="700">Grimstrup</text>
						<g id="dot-12" className="dot">
							<circle cx="207" cy="510.71" r="7" fill="#fff"/>
							<circle cx="207" cy="510.71" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="510.71" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-12" className="stop">
						<text id="caption-13" className="caption" transform="translate(224.09 554.89)" fontSize="14" fill="#00275e" fontWeight="700">Skævinge</text>
						<circle id="dot-13" className="dot" cx="207" cy="550.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-13" className="stop">
						<text id="caption-14" className="caption" transform="translate(224.09 596.12)" fontSize="14" fill="#00275e" fontWeight="700">Gørløse</text>
						<g id="dot-14" className="dot">
							<circle cx="207" cy="590.16" r="7" fill="#fff"/>
							<circle cx="207" cy="590.16" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="590.16" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-14" className="stop">
						<text id="caption-15" className="caption" transform="translate(224.09 635.34)" fontSize="14" fill="#00275e" fontWeight="700">Brødeskov</text>
						<g id="dot-15" className="dot">
							<circle cx="207" cy="630.38" r="7" fill="#fff"/>
							<circle cx="207" cy="630.38" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="630.38" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-15" className="stop">
						<text id="caption-16" className="caption" transform="translate(224.09 680.56)" fontSize="14" fill="#00275e" fontWeight="700">Favrholm</text>
						<circle id="dot-16" className="dot" cx="207" cy="673.61" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="end-stop">
						<text id="caption-17" className="caption" transform="translate(224.09 720.56)" fontSize="14" fill="#00275e" fontWeight="700">Hillerød</text>
						<circle id="dot-17" className="dot" cx="207" cy="715.61" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default FrederiksvaerkMobile;

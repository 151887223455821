import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const Toelloese = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 768">
			<g id="toelloese" fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path ref={routeRef} className="route" fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M145.04 361.93h958.61"/>
				<g className="stations">
					<g className="end-stop">
						<circle className="dot" cx="1103.65" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text className="caption" transform="rotate(-45 968.343 -1166.71)" fontSize="14" fill="#00275e" fontWeight="700">Tølløse</text>
					</g>
					<g className="stop">
						<g className="dot">
							<circle cx="1025.04" cy="361.93" r="7" fill="#fff"/>
							<circle cx="1025.04" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="1025.04" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text className="caption" transform="rotate(-45 929.033 -1071.808)" fontSize="14" fill="#00275e" fontWeight="700">Kirke Eskilstrup</text>
					</g>
					<g className="stop">
						<g className="dot">
							<circle cx="937.04" cy="361.93" r="7" fill="#fff"/>
							<circle cx="937.04" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="937.04" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text className="caption" transform="rotate(-45 886.083 -968.117)" fontSize="14" fill="#00275e" fontWeight="700">Store Merløse</text>
					</g>
					<g className="stop">
						<g className="dot">
							<circle cx="849.04" cy="361.93" r="7" fill="#fff"/>
							<circle cx="849.04" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="849.04" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text className="caption" transform="rotate(-45 841.143 -859.623)" fontSize="14" fill="#00275e" fontWeight="700">Bagmarken</text>
					</g>
					<g className="stop">
						<g className="dot">
							<circle cx="761.04" cy="361.93" r="7" fill="#fff"/>
							<circle cx="761.04" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="761.04" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text className="caption" transform="rotate(-45 796.558 -751.985)" fontSize="14" fill="#00275e" fontWeight="700">Nyrup</text>
					</g>
					<g className="stop">
						<g className="dot">
							<circle cx="673.04" cy="361.93" r="7" fill="#fff"/>
							<circle cx="673.04" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="673.04" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
						<text className="caption" transform="rotate(-45 753.743 -648.62)" fontSize="14" fill="#00275e" fontWeight="700">Stenlille</text>
					</g>
					<g className="stop">
						<text className="caption" transform="rotate(-45 709.533 -541.888)" fontSize="14" fill="#00275e" fontWeight="700" letterSpacing="-.05em">Vedde</text>
						<g className="dot">
							<circle cx="585.04" cy="361.93" r="7" fill="#fff"/>
							<circle cx="585.04" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="585.04" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g className="stop">
						<text className="caption" transform="rotate(-45 666.033 -436.87)" fontSize="14" fill="#00275e" fontWeight="700">Dianalund</text>
						<g className="dot">
							<circle cx="497.04" cy="361.93" r="7" fill="#fff"/>
							<circle cx="497.04" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="497.04" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g className="stop">
						<text className="caption" transform="rotate(-45 624.228 -335.944)" fontSize="14" fill="#00275e" fontWeight="700">Skellebjerg</text>
						<g className="dot">
							<circle cx="409.04" cy="361.93" r="7" fill="#fff"/>
							<circle cx="409.04" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="409.04" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g className="stop">
						<text className="caption" transform="rotate(-45 577.833 -223.936)" fontSize="14" fill="#00275e" fontWeight="700">Ruds Vedby</text>
						<g className="dot">
							<circle cx="321.04" cy="361.93" r="7" fill="#fff"/>
							<circle cx="321.04" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="321.04" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g className="stop">
						<text className="caption" transform="rotate(-45 534.443 -119.183)" fontSize="14" fill="#00275e" fontWeight="700">Høng</text>
						<g className="dot">
							<circle cx="233.04" cy="361.93" r="7" fill="#fff"/>
							<circle cx="233.04" cy="361.93" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="233.04" cy="361.93" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g className="start-stop">
						<circle className="dot" cx="145.04" cy="361.93" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
						<text className="caption" transform="rotate(-45 490.348 -12.729)" fontSize="14" fill="#00275e" fontWeight="700">Slagelse</text>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Toelloese;

import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const OdsherredMobile = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 414 768">
			<g id="odsherred-mobile" fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path ref={routeRef} className="route" fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M207 37.24v678.37"/>
				<g className="stations">
					<g className="start-stop">
						<text className="caption" transform="translate(224.09 42.19)" fontSize="14" fill="#00275e" fontWeight="700">Nykøbing Sjælland</text>
						<circle className="dot" cx="207" cy="37.24" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="stop">
						<text id="caption-2" className="caption" transform="translate(224.09 90.65)" fontSize="14" fill="#00275e" fontWeight="700">Nyled</text>
						<g id="dot-2" className="dot">
							<circle cx="207" cy="85.69" r="7" fill="#fff"/>
							<circle cx="207" cy="85.69" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="85.69" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-2" className="stop">
						<text id="caption-3" className="caption" transform="translate(224.09 139.11)" fontSize="14" fill="#00275e" fontWeight="700">Højby</text>
						<g id="dot-3" className="dot">
							<circle cx="207" cy="134.15" r="7" fill="#fff"/>
							<circle cx="207" cy="134.15" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="134.15" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-3" className="stop">
						<text id="caption-4" className="caption" transform="translate(224.09 187.56)" fontSize="14" fill="#00275e" fontWeight="700">Sommerland Sjælland</text>
						<g id="dot-4" className="dot">
							<circle cx="207" cy="182.6" r="7" fill="#fff"/>
							<circle cx="207" cy="182.6" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="182.6" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-4" className="stop">
						<text id="caption-5" className="caption" transform="translate(224.09 236.02)" fontSize="14" fill="#00275e" fontWeight="700">Nørre Asminderup</text>
						<g id="dot-5" className="dot">
							<circle cx="207" cy="231.06" r="7" fill="#fff"/>
							<circle cx="207" cy="231.06" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="231.06" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-5" className="stop">
						<text id="caption-6" className="caption" transform="translate(224.09 284.47)" fontSize="14" fill="#00275e" fontWeight="700" letterSpacing=".01em">Vig</text>
						<g id="dot-6" className="dot">
							<circle cx="207" cy="279.51" r="7" fill="#fff"/>
							<circle cx="207" cy="279.51" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="279.51" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-6" className="stop">
						<text id="caption-7" className="caption" transform="translate(224.09 332.92)" fontSize="14" fill="#00275e" fontWeight="700">Grevinge</text>
						<g id="dot-7" className="dot">
							<circle cx="207" cy="327.97" r="7" fill="#fff"/>
							<circle cx="207" cy="327.97" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="327.97" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-7" className="stop">
						<text id="caption-8" className="caption" transform="translate(224.09 381.38)" fontSize="14" fill="#00275e" fontWeight="700">Asnæs</text>
						<circle id="dot-8" className="dot" cx="207" cy="376.42" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g id="stop-8" className="stop">
						<text id="caption-9" className="caption" transform="translate(224.09 429.83)" fontSize="14" fill="#00275e" fontWeight="700">Fårevejle</text>
						<g id="dot-9" className="dot">
							<circle cx="207" cy="424.88" r="7" fill="#fff"/>
							<circle cx="207" cy="424.88" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="424.88" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-9" className="stop">
						<text id="caption-10" className="caption" transform="translate(224.09 478.29)" fontSize="14" fill="#00275e" fontWeight="700">Hørve</text>
						<g id="dot-10" className="dot">
							<circle cx="207" cy="473.33" r="7" fill="#fff"/>
							<circle cx="207" cy="473.33" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="473.33" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-10" className="stop">
						<text id="caption-11" className="caption" transform="translate(224.09 526.74)" fontSize="14" fill="#00275e" fontWeight="700">Svinninge</text>
						<g id="dot-11" className="dot">
							<circle cx="207" cy="521.79" r="7" fill="#fff"/>
							<circle cx="207" cy="521.79" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="521.79" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-11" className="stop">
						<text id="caption-12" className="caption" transform="translate(224.09 575.2)" fontSize="14" fill="#00275e" fontWeight="700">Gislinge</text>
						<g id="dot-12" className="dot">
							<circle cx="206.99" cy="570.24" r="7" fill="#fff"/>
							<circle cx="206.99" cy="570.24" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="206.99" cy="570.24" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-12" className="stop">
						<text id="caption-13" className="caption" transform="translate(224.09 623.65)" fontSize="14" fill="#00275e" fontWeight="700">Ny Hagested</text>
						<g id="dot-13" className="dot">
							<circle cx="206.99" cy="618.7" r="7" fill="#fff"/>
							<circle cx="206.99" cy="618.7" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="206.99" cy="618.7" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g id="stop-13" className="stop">
						<text id="caption-14" className="caption" transform="translate(224.09 672.11)" fontSize="14" fill="#00275e" fontWeight="700">Stenhus</text>
						<g id="dot-14" className="dot">
							<circle cx="206.99" cy="667.15" r="7" fill="#fff"/>
							<circle cx="206.99" cy="667.15" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="206.99" cy="667.15" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g className="end-stop">
						<text id="caption-15" className="caption" transform="translate(224.09 720.56)" fontSize="14" fill="#00275e" fontWeight="700">Holbæk</text>
						<circle id="dot-15" className="dot" cx="207" cy="715.61" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default OdsherredMobile;

import React from 'react';
import { RouteSvgProps } from '../types/route.types';

const LollandMobile = ({ svgRef, routeRef }: RouteSvgProps) => {
	return (
		<svg ref={svgRef} className='routes__map-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 414 768">
			<g id="lolland" fontFamily='Avenir, Helvetica Neue, Helvetica, Arial, sans-serif'>
				<path className="route" ref={routeRef} fill="none" stroke="#6593d5" strokeMiterlimit="10" strokeWidth="2" d="M207 37.24v678.37"/>
				<g className="stations">
					<g className="start-stop">
						<text className="caption" transform="translate(224.09 42.19)" fontSize="14" fill="#00275e" fontWeight="700">Nakskov</text>
						<circle className="dot" cx="207" cy="37.24" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="stop">
						<text className="caption" transform="translate(224.09 126.99)" fontSize="14" fill="#00275e" fontWeight="700" letterSpacing="-.02em">Avnede</text>
						<g className="dot">
							<circle cx="207" cy="122.04" r="7" fill="#fff"/>
							<circle cx="207" cy="122.04" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="122.04" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g className="stop">
						<text className="caption" transform="translate(224.09 211.79)" fontSize="14" fill="#00275e" fontWeight="700">Søllested</text>
						<circle className="dot" cx="207" cy="206.83" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="stop">
						<text className="caption" transform="translate(224.09 296.58)" fontSize="14" fill="#00275e" fontWeight="700">Ryde</text>
						<g className="dot">
							<circle cx="207" cy="291.63" r="7" fill="#fff"/>
							<circle cx="207" cy="291.63" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="291.63" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g className="stop">
						<text className="caption" transform="translate(224.09 381.38)" fontSize="14" fill="#00275e" fontWeight="700">Maribo</text>
						<circle className="dot" cx="207" cy="376.42" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="stop">
						<text className="caption" transform="translate(224.09 466.18)" fontSize="14" fill="#00275e" fontWeight="700">Sakskøbing</text>
						<circle className="dot" cx="207" cy="461.22" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
					<g className="stop">
						<text className="caption" transform="translate(224.09 550.97)" fontSize="14" fill="#00275e" fontWeight="700">Grænge</text>
						<g className="dot">
							<circle cx="207" cy="546.02" r="7" fill="#fff"/>
							<circle cx="207" cy="546.02" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="546.02" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g className="stop">
						<text className="caption" transform="translate(224.09 635.77)" fontSize="14" fill="#00275e" fontWeight="700">Øster Toreby</text>
						<g className="dot">
							<circle cx="207" cy="630.81" r="7" fill="#fff"/>
							<circle cx="207" cy="630.81" r="7" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="3"/>
							<circle cx="207" cy="630.81" r="7" fill="none" stroke="#6593d5" strokeMiterlimit="10"/>
						</g>
					</g>
					<g className="end-stop">
						<text className="caption" transform="translate(224.09 720.56)" fontSize="14" fill="#00275e" fontWeight="700">Nykøbing Falster</text>
						<circle className="dot" cx="207" cy="715.61" r="7" fill="#6593d5" stroke="#fff" strokeMiterlimit="10"/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default LollandMobile;
